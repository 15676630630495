import { Button } from "semantic-ui-react";
import logo from "../../logo.svg";

export const Home = () => {
  const clickHandler = () => {
    return fetch("/api/test-endpoint");
  };

  return (
    <div className="App-header">
      <h1>Personal area!</h1>
      <img src={logo} className="App-logo" alt="logo" />
      <p>REACT_APP_XXX = {process.env.REACT_APP_XXX}</p>
      <br />
      <Button onClick={clickHandler} color="blue">
        make api test call
      </Button>
    </div>
  );
};
