import { BrowserRouter as Router, Routes, Route, Link } from "react-router-dom";
import { useEffect, useState, useRef } from "react";
import { isExpired, decodeToken } from "react-jwt";
import { Button } from "semantic-ui-react";
import { Home } from "./pages/home/index";
import { Register } from "./pages/register/index";
import { Protected } from "./pages/protected/index";
import "./App.css";

export default function App() {
  const [isLoggedIn, setIsLogin] = useState(false);
  const [userName, setUserName] = useState("anonim");
  const [userEmail, setUserEmail] = useState("");

  const timerId = useRef<ReturnType<typeof setInterval>>();

  useEffect(() => {
    checkIsAuth();
    timerId.current = setInterval(checkIsAuth, 1000);
    // eslint-disable-next-line
  }, [isLoggedIn]);

  function checkIsAuth() {
    const token = localStorage.getItem("token");

    if (!token || isExpired(token)) {
      if (timerId.current) {
        clearInterval(timerId.current);
      }
      setIsLogin(false);
      setUserName("anonim");
      return undefined;
    } else {
      const data = decodeToken(token) as any;
      console.log("~ data", data);
      setIsLogin(true);
      setUserName(data.name || "no name");
      setUserEmail(data.email);
    }
  }

  const handlerLogout = () => {
    window.google.accounts.id.revoke(userEmail, () => null);
    localStorage.removeItem("token");
    window.location.assign("/");
  };

  return (
    <Router>
      <div className="App">
        <header className="user">
          <h1>hello - {userName || "anonim"}</h1>
        </header>
        <nav>
          <ul style={{ listStyle: "none" }}>
            <li>
              <br />
              <Link to="/">Home</Link>
            </li>
            <li>
              <br />
              <Link to="/register">Register new user</Link>
            </li>
            <li>
              <br />
              <Link to="/protected">proteced page</Link>
            </li>
            {isLoggedIn && (
              <li>
                <br />
                <Button onClick={handlerLogout} color="red">
                  Logout
                </Button>
              </li>
            )}
          </ul>
          <br />
        </nav>

        <Routes>
          <Route
            path="/register"
            element={
              <Register
                isLoggedIn={isLoggedIn}
                setLogin={() => setIsLogin(true)}
              />
            }
          />
          <Route path="/protected" element={<Protected />} />
          <Route path="/" element={<Home />} />
        </Routes>
      </div>
    </Router>
  );
}
