import { useState, useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";
import { Form, Button } from "semantic-ui-react";
import s from "./styles.module.css";

type TypeOnChange = {
  (
    e: import("react").ChangeEvent<HTMLInputElement>,
    data: import("semantic-ui-react").InputOnChangeData
  ): void;
};

type IProps = {
  isLoggedIn: boolean;
  setLogin: () => void;
};

export const Register = (props: IProps) => {
  const navigate = useNavigate();
  const [user, setUser] = useState("");
  const [password, setPassword] = useState("");
  const googleBtnRef = useRef(null);

  const handlerLoggedIn = (response: any) => {
    const idToken = response.credential;
    localStorage.setItem("token", idToken);
    props.setLogin();
    navigate("/protected");
  };

  useEffect(() => {
    window.google.accounts.id.initialize({
      client_id:
        "81341175677-tql4u1iae9nuqvju7veebtm5g1emoiqn.apps.googleusercontent.com",
      callback: handlerLoggedIn,
    });
    window.google.accounts.id.renderButton(
      googleBtnRef?.current,
      { theme: "outline", size: "large" } // customization attributes
    );
  }, []);

  const handlerRegister = (): void => {
    fetch("/api/register", {
      method: "POST",
      body: JSON.stringify({
        name: user,
        password: password,
      }),
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((response) => {
        if (!response.ok) throw new Error("===invalid response===");

        return response.json();
      })
      .then((data) => {
        alert(data.msg);
      })
      .catch((err) => {
        console.error("~ err", err);
      });
  };

  const handlerLogin = (): void => {
    fetch("/api/login", {
      method: "POST",
      body: JSON.stringify({
        name: user,
        password: password,
      }),
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((response) => {
        if (!response.ok) throw new Error("===invalid response===");

        return response.json();
      })
      .then((data) => {
        const token = data.token;
        localStorage.setItem("token", token);
        props.setLogin();
        navigate("/protected");
      })
      .catch((err) => {
        console.error("~ err", err);
      });
  };

  const handleChangeUser: TypeOnChange = (e, data) => {
    const { value } = data;
    setUser(value);
  };

  const handleChangePass: TypeOnChange = (e, data) => {
    const { value } = data;
    setPassword(value);
  };

  return (
    <div>
      <h1>Register page</h1>
      <br />
      <br />
      <br />
      <Form onSubmit={() => null} className={s.xxx}>
        <Form.Field>
          <label>User</label>
          <Form.Input
            placeholder="User..."
            name="user"
            value={user}
            onChange={handleChangeUser}
          />
        </Form.Field>
        <Form.Field>
          <label>Password</label>
          <Form.Input
            placeholder="Password ..."
            name="password"
            value={password}
            onChange={handleChangePass}
          />
        </Form.Field>
        <Button type="button" onClick={handlerRegister}>
          Register
        </Button>
        <br />
        <br />
        {!props.isLoggedIn && (
          <>
            <Button type="button" onClick={handlerLogin} color="green">
              Login
            </Button>
            <br />
            <br />
            <div ref={googleBtnRef}></div>
          </>
        )}
      </Form>
    </div>
  );
};
