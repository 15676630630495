import { useEffect } from "react";
import { isExpired } from "react-jwt";
import { useNavigate } from "react-router-dom";
import { Button } from "semantic-ui-react";

export const Protected = () => {
  const navigate = useNavigate();

  useEffect(() => {
    const token = localStorage.getItem("token");

    if (!token) {
      navigate("/");
      return undefined;
    }

    if (isExpired(token)) {
      navigate("/");
      return undefined;
    }
    // eslint-disable-next-line
  }, []);

  const handlerApi = () => {
    const token = localStorage.getItem("token");

    fetch("/api/protected-endpoint", {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    })
      .then((response) => {
        if (!response.ok) throw new Error("===invalid response===");

        return response.json();
      })
      .then((data) => {
        alert(data.msg);
      })
      .catch((err) => {
        console.error("~ err", err);
      });
  };

  return (
    <div>
      <h1>Protected page</h1>
      <br />
      <br />
      <h2>Secret info</h2>
      <br />
      <br />
      <p>
        Lorem ipsum dolor, sit amet consectetur adipisicing elit. Quos velit
        magnam accusantium illo numquam aut dignissimos blanditiis, magni,
        laborum quisquam ratione, neque iure enim quaerat eligendi vitae
        obcaecati unde. Pariatur!
      </p>
      <Button onClick={handlerApi}>test secure api</Button>
    </div>
  );
};
